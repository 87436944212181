import apiClient from '@/services/axios'

export async function getData(page, perPage, columns, filter, order) {
  return apiClient.post('/ortb/stats/site/ad', {
    Page: page,
    PerPage: perPage,
    Columns: columns,
    Order: order.filter(el => columns.includes(el.Column)),
    Filter: filter,
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function exportData(columns, filter, order, page, perPage) {
  return apiClient.post('/ortb/stats/site/ad/csv', {
    Columns: columns,
    Order: order,
    Filter: filter,
    Page: page,
    PerPage: perPage,
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
