<template>
  <b-container fluid="true">
    <!-- Reports Interface controls -->
  <h5 class="my-3"><strong>SITES BY AD</strong></h5>
    <a-row :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="multiple" allowClear placeholder="Advertisers" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.selectedAdvertisers.Values">
          <a-select-option v-for="obj in advertiser.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
        <a-button @click="addAdvertisers"><i class="fa fa-user"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="multiple" allowClear placeholder="Campaigns" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.selectedCampaigns.Values">
          <a-select-option v-for="obj in ortbCampaign.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
        <a-button @click="addCampaigns"><i class="fa fa-user"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Banner Ads" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.bannerads.Values">
          <a-select-option v-for="obj in bannerad.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Video Ads" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.videoads.Values">
          <a-select-option v-for="obj in videoad.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Remote RTB Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.remotertbzones.Values">
          <a-select-option v-for="obj in remotertbzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Remote Video Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.remotevideozones.Values">
          <a-select-option v-for="obj in remotevideozone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="multiple" allowClear placeholder="Publishers" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.selectedPublishers.Values">
          <a-select-option v-for="obj in ORTBpublisher.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
        <a-button @click="addPublishers"><i class="fa fa-user"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Banner Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.bannerzones.Values">
          <a-select-option v-for="obj in bannerzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Video Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.videozones.Values">
          <a-select-option v-for="obj in videozone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="RTB Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.rtbzones.Values">
          <a-select-option v-for="obj in rtbzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <!-- <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Operating Systems" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedOs.Values">
          <a-select-option v-for="obj in info.oss" :key="obj.Family">{{ obj.Family }}</a-select-option>
        </a-select>
      </a-col> -->
      <!-- <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Browsers" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedBrowsers.Values">
          <a-select-option v-for="obj in info.browsers" :key="obj.Family">{{ obj.Family }}</a-select-option>
        </a-select>
      </a-col> -->
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Countries" style="width: 100%" :showSearch="true"
          :filterOption="countryFilter" optionFilterProp="children" v-model="filteredColumns.selectedCountries.Values">
          <!-- <a-select-option v-for="obj in info.countries" :key="obj.CC">{{obj.Country}}</a-select-option> -->
          <a-select-option v-for="obj in info.countries" :key="obj.CC"><img :src="matchIcon(countries, obj.CC)" />
            {{ obj.Country }}</a-select-option>
        </a-select>
      </a-col>
      <!-- <a-col :xl="6" :md="12" :xs="24">
        <a-input v-model="filteredColumns.site.Contains" placeholder="Site" style="width: 100%" />
      </a-col> -->
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select
          mode="tags"
          allowClear
          placeholder="Sites"
          style="width: 100%"
          :showSearch="true"
          :tokenSeparators="[' ']"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.site.Values">
        </a-select>
        <a-button
          @click="copyBundles"><i class="fa fa-copy"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <div style="display: flex; justify-content: space-between">
          <a-range-picker :defaultValue="this.ranges['Today']" @change="setDateRange" :ranges="ranges"
            style="width: 70%" />
          <a-button type="primary" class="ml-2" @click="setFilters" style="width: 30%">
            Apply
          </a-button>
          <b-button size="sm" class="btn-info mr-1" @click="exportToCsv" title="Export to CSV" style="margin-left: 6px">
            <i class="fa fa-file-excel-o"></i>
          </b-button>
        </div>
      </a-col>
    </a-row>

    <b-row class="mt-2">
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'ortb-reports-site-ad'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-reports-site-ad-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Main table element -->
    <b-table sort-by="Revenue" :sort-desc="true" @sort-changed="sortingChanged" ref="table" show-empty :sort-direction="sortDirection" :small="true" :bordered="true" :responsive="true"
      :striped="true" :hover="true" stacked="md" :items="getTableData" :fields="filteredFields"
      :current-page="currentPage" :per-page="perPage" :no-local-sorting="true" @filtered="onFiltered" :busy="isBusy">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
      <template #cell(CC)="data">
        <div><img :src="matchIcon(countries, data.item.CC)" /> {{ data.item.CC }}</div>
      </template>
      <template #cell(Ivt)="data">
        <a>{{ data.item.Ivt }}%</a>
      </template>
      <template #cell(Sivt)="data">
        <a>{{ data.item.Sivt }}%</a>
      </template>
      <template #cell(Site)="data">
        <a :href="ensureHttpPrefix(data.item.Site)" target="_blank">{{ data.item.Site }}</a>
      </template>
      <template #cell(FilterRate)="data">
        <div class="background-indicator" :class="{ 'background-indicator-red': data.item.FilterRate > 70, 'background-indicator-orange': data.item.FilterRate > 40 && data.item.FilterRate <= 70, 'background-indicator-yellow': data.item.FilterRate > 10 && data.item.FilterRate <= 40 }">
          {{ parseFloat(data.item.FilterRate).toFixed(2) }}%
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
        }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import adColumns from './columns/adFields'
import { getData, exportData } from '@/api/display/reports_site_ad'
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import staticCountryData from '@/resources/countries.json'
import Vue from 'vue'

export default {
  name: 'reports',
  components: { columnSelect, perPageSelect },
  data() {
    return {
      countries: staticCountryData,
      reports: [],
      sortDirection: 'desc',
      userId: this.$store.state.auth.id,
      dateRange: {
        Column: 'DateStats',
        From: '',
        To: '',
        Values: [],
      },
      selectedDate: [moment().startOf('month'), moment().endOf('month')],
      filteredColumns: {
        selectedAdvertisers: {
          Column: 'AdvertiserId',
          Values: [],
        },
        selectedCampaigns: {
          Column: 'CampaignId',
          Values: [],
        },
        bannerads: {
          Column: 'BanneradId',
          Values: [],
        },
        videoads: {
          Column: 'VideoadId',
          Values: [],
        },
        remotertbzones: {
          Column: 'RemotertbzoneId',
          Values: [],
        },
        remotevideozones: {
          Column: 'RemotevideozoneId',
          Values: [],
        },
        selectedPublishers: {
          Column: 'PublisherId',
          Values: [],
        },
        bannerzones: {
          Column: 'BannerzoneId',
          Values: [],
        },
        videozones: {
          Column: 'VideozoneId',
          Values: [],
        },
        rtbzones: {
          Column: 'RtbzoneId',
          Values: [],
        },
        selectedCountries: {
          Column: 'CC',
          Values: [],
        },
        site: {
          Column: 'Site',
          Values: [],
        },
      },
      filters: [],
      thisMonth: [moment().startOf('month'), moment().endOf('month')],
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'This Week': [moment().startOf('isoWeek'), moment()],
        'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'),
          moment().subtract(1, 'week').endOf('isoWeek')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')],
      },
      loading: false,
      moment: moment,
      isBusy: false,
      tableData: [],
      fields: adColumns,
      sum: {},
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      order: [{ Column: 'Revenue', Ord: 'DESC' }],
      total: 0,
    }
  },
  created() {
    this.$store.dispatch('bannerad/LOAD_ADS_SHORT')
    this.$store.dispatch('videoad/LOAD_ADS_SHORT')
    this.$store.dispatch('remotertbzone/LOAD_ZONES_SHORT')
    this.$store.dispatch('remotevideozone/LOAD_ZONES_SHORT')
    this.$store.dispatch('bannerzone/LOAD_BANNERZONES_SHORT')
    this.$store.dispatch('videozone/LOAD_VIDEOZONES_SHORT')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
    this.$store.dispatch('advertiser/LOAD_ADVERTISERS_SHORT')
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.setDateRange(this.ranges.Today)
  },
  computed: {
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.total
    },
    sitesString() {
      return this.filteredColumns.site.Values.join('\n')
    },
    ...mapState(['ortbCampaign', 'advertiser', 'ad', 'ORTBpublisher', 'info', 'bannerzone', 'videozone', 'rtbzone', 'bannerad', 'videoad', 'remotertbzone', 'remotevideozone']),
    ...mapGetters('advertiser', ['getActiveAdvertisers']),
    ...mapGetters('ORTBpublisher', ['getActivePublishers']),
    ...mapGetters('ortbCampaign', ['getActiveORTBCampaigns']),
  },
  methods: {
    addAdvertisers() {
      this.filteredColumns.selectedAdvertisers.Values = []
      this.getActiveAdvertisers.forEach(el => {
        if (el.AccManagerId === this.userId) {
          this.filteredColumns.selectedAdvertisers.Values.push(el.Id)
        }
      })
    },
    copyBundles() {
      navigator.clipboard.writeText(this.sitesString)
      Vue.prototype.$notification.success({
        message: 'Sites copied to clipboard.',
      })
    },
    addPublishers() {
      this.filteredColumns.selectedPublishers.Values = []
      this.getActivePublishers.forEach(el => {
        if (el.AccManagerId === this.userId) {
          this.filteredColumns.selectedPublishers.Values.push(el.Id)
        }
      })
    },
    addCampaigns() {
      this.filteredColumns.selectedCampaigns.Values = []
      this.ortbCampaign.list.forEach(el => {
        if (el.AccManagerId === this.userId && el.Status === 0) {
          this.filteredColumns.selectedCampaigns.Values.push(el.Id)
        }
      })
    },
    ensureHttpPrefix(url) {
      if (!url.startsWith('https://') && !url.startsWith('http://')) {
        return 'http://' + url
      }
      return url
    },
    countryFilter(inputValue, option) {
      return option.componentOptions.children[1].text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      // comment
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    setFilters() {
      const array = []
      for (const col in this.filteredColumns) {
        if (this.filteredColumns[col].Values && this.filteredColumns[col].Values.length !== 0) {
          array.push(this.filteredColumns[col])
        } else if (this.filteredColumns[col].Contains && this.filteredColumns[col].Contains !== '') {
          array.push(this.filteredColumns[col])
        }
      }
      if (this.dateRange.From !== '' && this.dateRange.To !== '') {
        array.push(this.dateRange)
      }
      this.filters = array
      this.$refs.table.refresh()
    },
    sortingChanged(ctx) {
      this.order = []
      this.order.push({
        Column: ctx.sortBy,
        Ord: ctx.sortDesc ? 'DESC' : 'ASC',
      })
      // this.$refs.table.refresh()
    },
    setDateRange(date) {
      this.filters = this.filters.filter(ele => ele.Column !== 'Date')
      this.dateRange.From = date[0] ? date[0].format('YYYY-MM-DD') : ''
      this.dateRange.To = date[1] ? date[1].format('YYYY-MM-DD') : ''
      this.filters.push(this.dateRange)
    },
    getTableData(ctx, callback) {
      this.loading = true
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      getData(this.currentPage, this.perPage, columns, this.filters, this.order).then(response => {
        this.tableData = response.Data
        this.sum = response.Sum
        this.total = response.Total
        this.loading = false
        callback(response.Data)
      })
      return null
    },
    exportToCsv() {
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      exportData(columns, this.filters, this.order, this.currentPage, this.perPage).then(response => {
        const blob = new Blob([response], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'reports_sites_by_ad_' + moment().format('YYYY-MM-DD') + '.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    callback(value) {
      return value
    },
  },
  watch: {
    fields: {
      handler(val) {
        this.$refs.table.refresh()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.custom-report-input-wrapper {
  display: flex;
  justify-content: center;
}

.custom-report-input-wrapper>.ant-select {
  width: 100%;
  margin-right: 2px;
}
.custom-report-input-wrapper>.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}
</style>
